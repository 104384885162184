// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-productall-js": () => import("./../src/templates/productall.js" /* webpackChunkName: "component---src-templates-productall-js" */),
  "component---src-templates-product-js": () => import("./../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-pages-about-jsx": () => import("./../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-allproducts-jsx": () => import("./../src/pages/allproducts.jsx" /* webpackChunkName: "component---src-pages-allproducts-jsx" */),
  "component---src-pages-brewery-jsx": () => import("./../src/pages/brewery.jsx" /* webpackChunkName: "component---src-pages-brewery-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

